import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Contact from '../components/forms/Contact';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';
import { Helmet } from 'react-helmet';

const Contacts = () => {
  const { t } = useTranslation(['components\\forms\\formTr']);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title-html-contact')}</title>
      </Helmet>
      <section className="img-background">
        <div className="main-title">
          <h1>{t('contact-title')}</h1>
        </div>

        <SocialNetworksMainBackground />
      </section>
      <Contact listenerIsContact={true} />
    </>
  );
};

export default Contacts;
