import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';
import { Helmet } from 'react-helmet';
import '../styles/pages/_surfTrips.scss';

const SurfTrips = () => {
  const { t } = useTranslation(['pages\\surfTripsTr']);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title-html')}</title>
      </Helmet>
      <section className="img-background">
        <div className="main-title">
          <h1>{t('title')}</h1>
          <p className="description">{t('description')}</p>
        </div>

        <SocialNetworksMainBackground />
      </section>

      <section className="container">
        <div className="block-travel">
          <img src="img/pages/surfTrips/voyage-surf-maroc.webp" alt="Voyage Surf au Maroc" />
          <div className="block-description">
            <p className="title-blue-global">{t('maroc.title')}</p>
            <p className="description-global">{t('maroc.description.part1')}</p>
            <p className="description-global">{t('maroc.description.part2')}</p>
            <p className="description-global">{t('maroc.description.part3')}</p>
          </div>
        </div>

        <div className="block-travel block-reverse">
          <img
            src="img/pages/surfTrips/voyage-surf-pays-basque-espagnol.webp"
            alt="Voyage surf dans le pays Basque espagnol et découverte des décors de Game of Thrones"
          />
          <div className="block-description">
            <p className="title-blue-global">{t('pays-basque.title')}</p>
            <p className="description-global">{t('pays-basque.description.part1')}</p>
            <p className="description-global">{t('pays-basque.description.part2')}</p>
          </div>
        </div>

        <div className="more">
          <button onClick={() => navigate('/contacts?subject=[SURF TRIPS]')} className="btn-blue">
            {t('more')}
          </button>
        </div>
      </section>
    </>
  );
};

export default SurfTrips;
