import { useState } from 'react';
import '../styles/components/_cardOpinion.scss';

interface INotif {
  listenerName: string;
  listenerComment: string;
  listenerFullComment: string;
}

const CardOpinion = ({ listenerName, listenerComment, listenerFullComment }: INotif) => {
  const [display, setDisplay] = useState(false);
  const elipsis = '...';

  return (
    <article className="card-opinion">
      <div className="infos">
        <div className="name">
          <p>{listenerName}</p>
        </div>
        <div className="stars">
          <img src="img/components/card-opinion/star.png" alt="star" />
          <img src="img/components/card-opinion/star.png" alt="star" />
          <img src="img/components/card-opinion/star.png" alt="star" />
          <img src="img/components/card-opinion/star.png" alt="star" />
          <img src="img/components/card-opinion/star.png" alt="star" />
        </div>
        <div className="comment" onClick={() => setDisplay(!display)}>
          <p>
            {listenerComment}
            {!display && elipsis}
            {display && listenerFullComment}
          </p>
        </div>
      </div>
    </article>
  );
};

export default CardOpinion;
