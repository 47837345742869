import { Link } from 'react-router-dom';
import '../../styles/components/tools/_footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="content">
        <div className="social-networks">
          <a
            href={'https://instagram.com/surf_perspective_tarnos?igshid=YmMyMTA2M2Y='}
            aria-label="Instagram"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className="social-logo">
              <span>
                <img src="img/components/tools/instagram-blue.svg" alt="Instagram" />
              </span>
            </div>
          </a>
          <a
            href={'https://www.facebook.com/surfperspectivetarnos/?ref=page_internal'}
            aria-label="Facebook"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className="social-logo">
              <span>
                <img src="img/components/tools/facebook-blue.svg" alt="Facebook" />
              </span>
            </div>
          </a>
        </div>

        <div className="text-footer">
          <p>
            Une réalisation <a href="https://manipogo.fr/">manipogo.fr</a> |{' '}
            <Link to={'/values#eco-responsable'} style={{ cursor: 'pointer' }}>
              Eco-responsable
            </Link>{' '}
            | <Link to={'/cgu'}>CGU</Link> | <Link to={'/cgv'}>CGV</Link>
          </p>
          <p>©️2022 SURF PERSPECTIVE</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
