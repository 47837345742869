import React from 'react';

const SocialNetworksMainBackground = () => {
  return (
    <div className="social-networks-main-background">
      <a
        href={'https://www.facebook.com/surfperspectivetarnos/?ref=page_internal'}
        target="_blank"
        rel="noreferrer noopener"
      >
        <div>
          <img src="img/components/tools/facebook.svg" alt="Facebook" />
        </div>
      </a>
      <a
        href={'https://instagram.com/surf_perspective_tarnos?igshid=YmMyMTA2M2Y='}
        target="_blank"
        rel="noreferrer noopener"
      >
        <div>
          <img src="img/components/tools/instagram.svg" alt="Instagram" />
        </div>
      </a>
      <a
        href={
          'https://www.google.com/maps/place/SURF+PERSPECTIVE/@43.545614,-1.4937665,17z/data=!3m1!4b1!4m5!3m4!1s0xd51419c7800983f:0x5b61b83d360d73ba!8m2!3d43.5456101!4d-1.4915778?hl=fr'
        }
        target="_blank"
        rel="noreferrer noopener"
      >
        <div>
          <img src="img/components/tools/maps.svg" alt="Maps" />
        </div>
      </a>
    </div>
  );
};

export default SocialNetworksMainBackground;
