import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';
import { Helmet } from 'react-helmet';
import '../styles/pages/_valuesAndPartners.scss';

const ValuesAndPartners = () => {
  const { t } = useTranslation(['pages\\valuesAndPartnersTr']);

  useEffect(() => {
    if (window.location.href.includes('#eco-responsable')) {
      document.getElementById('eco-responsable')?.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title-html')}</title>
      </Helmet>
      <section className="img-background">
        <div className="main-title">
          <h1>{t('title')}</h1>
        </div>

        <SocialNetworksMainBackground />
      </section>
      <section className="container">
        <div className="values-sentence">
          <span>{t('mindset')}</span>
        </div>
      </section>
      <section className="values-history">
        <div className="block container">
          <div className="block-img">
            <img src="img/pages/valuesAndPartners/upcycling-combinaisons-surf.webp" alt="Recyclage de néoprène" />
          </div>

          <div className="block-text">
            <p className="title-global">{t('values.one.title')}</p>
            <p className="description-global">{t('values.one.description')}</p>
          </div>
        </div>

        <div className="block reverse container">
          <div className="block-img">
            <img
              src="img/pages/valuesAndPartners/surf-respectueux-environnement.webp"
              alt="Faire du tourisme durable"
            />
          </div>

          <div className="block-text">
            <p className="title-global">{t('values.two.title')}</p>
            <p className="description-global">{t('values.two.description.part1')}</p>
            <p className="description-global">{t('values.two.description.part2')}</p>
          </div>
        </div>

        <div className="block container">
          <div className="block-img">
            <img
              src="img/pages/valuesAndPartners/tarnos.webp"
              alt="Session de surf au coucher de soleil dans les Landes à Tarnos"
            />
          </div>

          <div className="block-text">
            <p className="title-global">{t('values.three.title')}</p>
            <p className="description-global">{t('values.three.description')}</p>
          </div>
        </div>
      </section>

      <section className="partners container">
        <div className="title-blue-global">
          <p>{t('partners.title')}</p>
        </div>
        <div className="block">
          <div className="block-img">
            <a href="https://zeussurfboards.com/" target="_blank" rel="noreferrer noopener">
              <img src="img/pages/valuesAndPartners/zeus.webp" alt="Planches de surf éco-conçues" />
            </a>
          </div>
          <div className="block-text">
            <div className="title-group">
              <a href="https://zeussurfboards.com/" target="_blank" rel="noreferrer noopener">
                <img src="img/pages/valuesAndPartners/zeus.webp" alt="Planches de surf éco-conçues" />
              </a>
              <div className="sub-title">{t('partners.zeus.title')}</div>
            </div>
            <div className="description-global">
              <p className="sub-title">{t('partners.zeus.sub-title')}</p>
              <p>{t('partners.zeus.description')}</p>
            </div>
          </div>
        </div>
        <div className="block">
          <div className="block-img">
            <a href="https://surfwear.sooruz.com/" target="_blank" rel="noreferrer noopener">
              <img src="img/pages/valuesAndPartners/sooruz.webp" alt="Combinaisons de surf éco-responsables" />
            </a>
          </div>
          <div className="block-text">
            <div className="title-group">
              <a href="https://surfwear.sooruz.com/" target="_blank" rel="noreferrer noopener">
                <img src="img/pages/valuesAndPartners/sooruz.webp" alt="Combinaisons de surf éco-responsables" />
              </a>
              <div className="sub-title">{t('partners.sooruz.title')}</div>
            </div>
            <div className="description-global">
              <p className="sub-title">{t('partners.sooruz.sub-title')}</p>
              <p>{t('partners.sooruz.description')}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="site-eco" id="eco-responsable">
        <div className="container">
          <div className="title-global text-center">{t('site-eco.title')}</div>
          <div className="description-global text-center">
            <p>{t('site-eco.description.part1')}</p>
            <p>{t('site-eco.description.part2')}</p>
            <p>
              {t('site-eco.description.part3')}{' '}
              <a href="https://www.greenit.fr/definition/" target="_blank" rel="noreferrer noopener">
                Green IT
              </a>
            </p>
          </div>
        </div>
      </section>
      <section className="partners-logo container">
        <div className="title-blue-global">
          <p>{t('partners-logo.title')}</p>
        </div>
        <div className="logos">
          <div className="part1">
            <div className="logo">
              <a href="https://zeussurfboards.com/" target="_blank" rel="noreferrer noopener">
                <img src="img/pages/valuesAndPartners/zeus.webp" alt="Planches de surf éco-conçues" />
              </a>
            </div>
            <div className="logo">
              <a href="https://www.ecosafesurfing.com/" target="_blank" rel="noreferrer noopener">
                <img src="img/pages/valuesAndPartners/eco-safe.webp" alt="Une garantie de cours de surf de qualité" />
              </a>
            </div>
            <div className="logo">
              <a href="https://www.seignanx.com/" target="_blank" rel="noreferrer noopener">
                <img
                  src="img/pages/valuesAndPartners/Logo-Seignanx.webp"
                  alt="Une garantie de cours de surf de qualité"
                />
              </a>
            </div>
          </div>
          <div className="part1">
            <div className="logo">
              <a href="https://surfinsertion.com/" target="_blank" rel="noreferrer noopener">
                <img
                  src="img/pages/valuesAndPartners/logo-surf-insertion.webp"
                  alt="Le surf comme vecteur de cohésion sociale"
                />
              </a>
            </div>
            <div className="logo">
              <a href="https://surfwear.sooruz.com/" target="_blank" rel="noreferrer noopener">
                <img src="img/pages/valuesAndPartners/sooruz.webp" alt="Combinaisons de surf éco-responsables" />
              </a>
            </div>
            <div className="logo">
              <a href="https://www.tourisme-durable.org/" target="_blank" rel="noreferrer noopener">
                <img
                  src="img/pages/valuesAndPartners/surf-respectueux-environnement.webp"
                  alt="Faire du tourisme durable"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ValuesAndPartners;
