import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';
import { Helmet } from 'react-helmet';

const CGU = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Conditions générales d’utilisation</title>
      </Helmet>
      <section className="img-background">
        <div className="main-title">
          <h1>Conditions générales d’utilisation</h1>
        </div>

        <SocialNetworksMainBackground />
      </section>
      <section className="container">
        <p className="title-global">Conditions générales d’utilisation du site surfperspective.com</p>
        <div className="description-global">
          Les présentes Conditions Générales d’Utilisation (ou CGU) encadrent juridiquement l’utilisation des services
          du site surfperspective.com (ci-après dénommé «le site»).
        </div>
        <div className="description-global">L’accès à ce site signifie l’acceptation des présentes CGU.</div>

        <p className="title-global">Mentions légales</p>
        <div className="description-global">
          Les mentions légales sont accessibles depuis le lien : <Link to="/legal-notice">Mentions légales</Link>
        </div>

        <p className="title-global">Responsabilité</p>
        <div className="description-global">
          Patrick Cedras s’efforce de fournir sur le site surfperspective.com des informations les plus précises
          possibles (sous réserve de modifications apportées depuis leur mise en ligne) mais ne saurait garantir
          l’exactitude, la complétude et l’actualité des informations diffusées sur son site.
        </div>
        <div className="description-global">
          En conséquence, l’utilisateur reconnaît utiliser ces informations données (à titre indicatif, non exhaustives
          et susceptibles d’évoluer) sous sa responsabilité exclusive.
        </div>
        <p className="title-global">Accessibilité</p>
        <div className="description-global">
          Le site surfperspective.com est par principe accessible aux utilisateurs 24/24h, 7/7j, sauf en cas
          interruption programmée ou non, pour les besoins de sa maintenance ou en cas de force majeure. En cas
          d’impossibilité d’accès au service, le surfperspective.com s’engage à faire son maximum afin de rétablir
          l’accès au service.
        </div>
        <div className="description-global">
          N’étant soumis qu’à une obligation de moyen, surfperspective.com ne saurait être tenu pour responsable de tout
          dommage, quelle qu’en soit la nature, résultant d’une indisponibilité du service.
        </div>
        <p className="title-global">Données personnelles</p>
        <div className="description-global">
          Le site surfperspective.com n’utilise pas de bases de données et ne stocke pas de données, ainsi il ne
          collecte aucune informations personnelles relatives à l’utilisateur.
        </div>
        <div className="description-global">
          L&apos;utilisateur peut de lui-même fournir des informations le concernant via le formulaire de contact
          présent sur le site. Aucune information personnelle transmise via ce formulaire n’est publiée à l’insu de
          l’utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers.
        </div>
      </section>
    </>
  );
};

export default CGU;
