import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import './i18n';
import { Suspense } from 'react';
import Coach from './pages/Coach';
import ValuesAndPartners from './pages/ValuesAndPartners';
import SurfCourses from './pages/SurfCourses';
import SurfTrips from './pages/SurfTrips';
import SurfCamp from './pages/SurfCamp';
import Location from './pages/Location';
import Gallery from './pages/Gallery';
import Contacts from './pages/Contacts';
import Booking from './pages/Booking';
import LegalNotice from './pages/LegalNotice';
import CGU from './pages/CGU';
import CGV from './pages/CGV';
import Layout from './components/layout/Layout';

// loading component for suspense fallback
const Loader = () => (
  <div>
    <div>Loading</div>
  </div>
);

type route = {
  path: string;
  element: JSX.Element;
  classname: string;
};

const routes: route[] = [
  { path: '*', element: <Home />, classname: 'home' },
  { path: 'coach', element: <Coach />, classname: 'coach' },
  { path: 'values', element: <ValuesAndPartners />, classname: 'values' },
  { path: 'cours', element: <SurfCourses />, classname: 'surf-courses' },
  { path: 'trips', element: <SurfTrips />, classname: 'trip' },
  { path: 'camp', element: <SurfCamp />, classname: 'camp' },
  { path: 'location', element: <Location />, classname: 'location' },
  { path: 'gallery', element: <Gallery />, classname: 'gallery' },
  { path: 'contacts', element: <Contacts />, classname: 'contacts' },
  { path: 'booking', element: <Booking />, classname: 'contacts form-booking-choice' },
  { path: 'legal-notice', element: <LegalNotice />, classname: 'legal-notice' },
  { path: 'cgv', element: <CGV />, classname: 'legal-notice' },
  { path: 'cgu', element: <CGU />, classname: 'legal-notice' },
];

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter basename="/">
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<Layout classname={route.classname}>{route.element}</Layout>}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
