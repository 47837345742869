import { useEffect } from 'react';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';
import { Helmet } from 'react-helmet';
import '../styles/pages/_legalNotice.scss';

const LegalNotice = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Mentions légales</title>
      </Helmet>
      <section className="img-background">
        <div className="main-title">
          <h1>Mentions légales</h1>
        </div>

        <SocialNetworksMainBackground />
      </section>
      <section className="container">
        <p className="title-global">Édition du site web</p>

        <p className="sub-title">Propriétaire : </p>
        <div className="description-global">
          <p>
            Le présent site web, accessible à partir de l’adresse URL{' '}
            <a href="https://surfperspective.com" target="_blank" rel="noreferrer noopener">
              https://surfperspective.com
            </a>
            , est la propriété de Patrick Cedras.
          </p>
          <p>
            Mail : <a href="mailto:contact@surfperspective.com">contact@surfperspective.com</a>
          </p>
          <p>
            Téléphone : <a href="tel:+33756996506">+33 (0) 7 56 99 65 06</a>
          </p>
        </div>

        <p className="sub-title">Coopérative : </p>
        <div className="description-global">
          <p>Activité portée par la SCIC Interstices Sud Aquitaine</p>
          <p>Numéro de TVA Intracommunautaire : FR02448220830</p>
          <p>Adresse : 3 Rue Hélène Boucher 40220 Tarnos.</p>
          <p>
            Site :{' '}
            <a href="www.interstices-sud-aquitaine.fr" target="_blank" rel="noreferrer noopener">
              www.interstices-sud-aquitaine.fr
            </a>
          </p>
          <p>
            Mail :{' '}
            <a href="mailto:contact@interstices-sud-aquitaine.fr" target="_blank" rel="noreferrer noopener">
              contact@interstices-sud-aquitaine.fr
            </a>
          </p>
          <p>
            Téléphone : <a href="tel:+33647545214">+33 (0) 5 59 74 84 10</a>
          </p>
        </div>

        <p className="title-global">Hébergement</p>
        <div className="description-global">
          <p>Le présent site web est hébergé par la société OVH.</p>
          <p>Siège social : 2 rue Kellermann - 59100 Roubaix, France</p>
          <p>
            Téléphone : <a href="tel:+33899701761">+33 (0) 8 99 70 17 61</a>
          </p>
          <p>
            Site :{' '}
            <a href="https://ovh.com" target="_blank" rel="noreferrer noopener">
              https://ovh.com
            </a>
          </p>
        </div>
        <p className="title-global">Création et réalisation</p>
        <div className="description-global">
          <p>Le présent site est une création Manipogo</p>
          <p>
            Site :{' '}
            <a href="https://manipogo.fr" target="_blank" rel="noreferrer noopener">
              https://manipogo.fr
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

export default LegalNotice;
