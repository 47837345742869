import { useTranslation } from 'react-i18next';
import Contact from '../components/forms/Contact';
import CardCourse from '../components/CardCourse';
import CardOpinion from '../components/CardOpinion';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';
import { Helmet } from 'react-helmet';
import '../styles/pages/_home.scss';

const Home = () => {
  const { t } = useTranslation(['pages\\homeTr']);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title-html')}</title>
      </Helmet>

      <section className="img-background">
        <div className="titles">
          <h1 className="title">
            {t('title.part1')} <span>{t('title.part2')}</span>
          </h1>
          <h2 className="sub-title">{t('sub-title')}</h2>
          <p className="description">{t('description')}</p>
        </div>
        <SocialNetworksMainBackground />
      </section>
      <section className="container">
        <div className="surf-school">
          <p className="title title-blue-global">{t('surf-school.title')}</p>
          <p className="description description-global">{t('surf-school.description')}</p>
        </div>
      </section>
      <section className="coach-home">
        <div className="coach-img">
          <img
            src="img/pages/home/moniteur-surf-passionne.webp"
            alt="Moniteur et créateur de l'école de surf éco-responsable à Tarnos"
          />
        </div>
        <div className="container">
          <div className="block-right">
            <div className="title title-blue-global">{t('coach.title')}</div>
            <div className="description description-global">
              <p title="Un moniteur de surf passionné pour vos cours de surf dans les landes et pays basque">
                {t('coach.description')}
              </p>
            </div>
            <div className="btn-more">
              <button onClick={() => navigate('/coach')} className="btn-blue">
                {t('coach.more')}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="courses container">
        <div className="title-blue-global text-center">
          <p>{t('course.title')}</p>
        </div>
        <div className="cards">
          <div className="card">
            <CardCourse
              listenerColor="red"
              listenerImg="cours-surf-collectifs.webp"
              listenerTitle={t('course.classic')}
            />
          </div>
          <div className="card">
            <CardCourse
              listenerColor="blue"
              listenerImg="cours-surf-particulier.webp"
              listenerTitle={t('course.improvement')}
            />
          </div>
          <div className="card">
            <CardCourse
              listenerColor="red"
              listenerImg="transport-spot-surf.webp"
              listenerTitle={t('course.pick-up')}
            />
          </div>
        </div>
      </section>

      <section className="surf-stuffs">
        <div className="surf-block container">
          <div className="img-surf">
            <img src="img/pages/home/surf-camp.webp" alt="surf camp" />
          </div>
          <div className="block">
            <div className="title title-global">
              <p>{t('surf-camp.title')}</p>
            </div>
            <div className="description">
              <p>{t('surf-camp.description')}</p>
            </div>
            <button onClick={() => navigate('/camp')} className="btn-transparent">
              {t('surf-camp.more')}
            </button>
          </div>
        </div>
        <div className="surf-block surf-block-reverse container">
          <div className="img-surf">
            <img src="img/pages/home/surf-trip.webp" alt="surf trip" />
          </div>
          <div className="block">
            <div className="title title-global">
              <p>{t('surf-trip.title')}</p>
            </div>
            <div className="description">
              <p>{t('surf-trip.description.part1')}</p>
              <p>{t('surf-trip.description.part2')}</p>
            </div>
            <button onClick={() => navigate('/trips')} className="btn-transparent">
              {t('surf-trip.more')}
            </button>
          </div>
        </div>
      </section>

      <section className="opinions container">
        <div className="title-blue-global text-center">
          <p>{t('opinions.title')}</p>
        </div>
        <div className="opinions-card">
          <div className="card">
            <CardOpinion
              listenerName={t('opinions.cards.one.name')}
              listenerComment={t('opinions.cards.one.comment')}
              listenerFullComment={t('opinions.cards.one.full-comment')}
            />
          </div>
          <div className="card">
            <CardOpinion
              listenerName={t('opinions.cards.two.name')}
              listenerComment={t('opinions.cards.two.comment')}
              listenerFullComment={t('opinions.cards.two.full-comment')}
            />
          </div>
          <div className="card">
            <CardOpinion
              listenerName={t('opinions.cards.three.name')}
              listenerComment={t('opinions.cards.three.comment')}
              listenerFullComment={t('opinions.cards.three.full-comment')}
            />
          </div>
        </div>
        <div className="more text-center">
          <Link to="/coach#opinions">
            <button className="btn-blue">{t('opinions.more')}</button>
          </Link>
        </div>
      </section>
      <section className="gallery-home-background">
        <div className="gallery-home container">
          <div className="title-global text-center">
            <p>{t('gallery.title')}</p>
          </div>
          <div className="pictures">
            <img
              className="img-square"
              src="img/pages/gallery/surfeur-en-route-vers-ocean.webp"
              alt="Surfeur en route vers l'océan pour une session de Surf à Tarnos"
            />
            <img
              className="img-square"
              src="img/pages/gallery/echauffement-surf.webp"
              alt="Echauffement Surf sur l'un des plus beaux spots de surf du Maroc"
            />
            <img
              className="img-square"
              src="img/pages/gallery/souvenirs-cours-surf-maroc.webp"
              alt="Souvenirs d'un cours de surf au Maroc, élève et moniteur de surf prennent la pose"
            />
            <img
              className="img-square"
              src="img/pages/gallery/explication-technique-redressement-surf.webp"
              alt="Explication de la technique du redressement en surf sur la plage de Zumaia en Espagne"
            />
            <img
              className="img-square"
              src="img/pages/gallery/moniteur-surf-avec-planche-fetishe.webp"
              alt="Moniteur de surf avec sa planche fétishe"
            />
            <img
              className="img-square"
              src="img/pages/gallery/groupe-surfeurs-imsouane-maroc.webp"
              alt="Groupe de surfeurs à Imsouane au Maroc unis à l'occasion de l'évènement Rame pour ta planète"
            />
          </div>
          <div className="more">
            <button onClick={() => navigate('/gallery')} className="btn-transparent">
              {t('gallery.more')}
            </button>
          </div>
        </div>
      </section>

      <Contact listenerIsContact={false} />

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6492.147487299758!2d-1.4970388490979951!3d43.54564785160031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd514185f9e0c2a9%3A0x50693be3723ba311!2s3%20Rue%20H%C3%A9l%C3%A8ne%20Boucher%2C%2040220%20Tarnos!5e0!3m2!1sfr!2sfr!4v1653078172699!5m2!1sfr!2sfr"
        width="600"
        height="450"
        style={{ border: '0' }}
        loading="lazy"
        title="Localisation de l\'école de surf à Tarnos"
      ></iframe>
    </>
  );
};

export default Home;
