import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../styles/components/_cardCourse.scss';

interface INotif {
  listenerColor: string;
  listenerImg: string;
  listenerTitle: string;
}

const CardCourse = ({ listenerColor, listenerImg, listenerTitle }: INotif) => {
  const [cssBtn, setCssBtn] = useState<string>('btn-red');
  const [cssTitle, setCssTitle] = useState<string>('title-red');
  const [imgLink, setImgLink] = useState<string>('');
  const { t } = useTranslation(['pages\\homeTr']);
  const navigate = useNavigate();

  useEffect(() => {
    if (listenerColor === 'blue') {
      setCssBtn('btn-blue');
      setCssTitle('title-blue');
    }
    setImgLink(listenerImg);
  }, []);

  return (
    <article className="card-course">
      <img src={'img/components/card-course/' + imgLink} alt="surfboard red" />
      <div className="card-yellow">
        <p className={'title-card ' + cssTitle}>{listenerTitle}</p>
        <button onClick={() => navigate('/cours')} className={'btn-transparent ' + cssBtn}>
          {t('course.more')}
        </button>
      </div>
    </article>
  );
};

export default CardCourse;
