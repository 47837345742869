import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardOpinion from '../components/CardOpinion';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';
import { Helmet } from 'react-helmet';
import '../styles/pages/_coach.scss';

const Coach = () => {
  const { t } = useTranslation(['pages\\coachTr']);

  let opinions_url = process.env.REACT_APP_OPINIONS;
  if (window.innerWidth < 1200) {
    opinions_url = process.env.REACT_APP_OPINIONS_SMARTPHONE;
  }

  useEffect(() => {
    const scrollTarget = window.location.hash.substr(1); // Récupère l'id depuis l'URL sans le '#'
    if (scrollTarget) {
      const targetElement = document.getElementById(scrollTarget);

      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, []); // Le tableau vide [] indique que useEffect s'exécute seulement une fois après le montage initial

  const [stepOpinions, setStepOpinions] = useState<number>(0);

  return (
    <>
      <Helmet>
        <title>{t('title-html')}</title>
      </Helmet>
      <section className="img-background">
        <div className="main-title">
          <h1>{t('title')}</h1>
        </div>
        <SocialNetworksMainBackground />
      </section>
      <section className="container blocks">
        <div className="img-coach">
          <img src="img/pages/coach/coach.webp" alt="Coach" />
        </div>
        <div className="description-coach">
          <div className="title-blue-global">{t('coach.title')}</div>
          <div className="description-global">{t('coach.description.part1')}</div>
          <div className="description-global">{t('coach.description.part2')}</div>
          <div className="description-global">{t('coach.description.part3')}</div>
        </div>
      </section>
      <section className="container opinions" id="opinions">
        <div className="title-blue-global text-center">
          <p>{t('opinions.title')}</p>
        </div>
        <div className="sub-title-blue-global text-center">
          <p>{t('opinions.sub-title')}</p>
        </div>
        <div className="opinions-card">
          <div className="card">
            <CardOpinion
              listenerName={t('opinions.cards.one.name')}
              listenerComment={t('opinions.cards.one.comment')}
              listenerFullComment={t('opinions.cards.one.full-comment')}
            />
          </div>
          <div className="card">
            <CardOpinion
              listenerName={t('opinions.cards.two.name')}
              listenerComment={t('opinions.cards.two.comment')}
              listenerFullComment={t('opinions.cards.two.full-comment')}
            />
          </div>
          <div className="card">
            <CardOpinion
              listenerName={t('opinions.cards.three.name')}
              listenerComment={t('opinions.cards.three.comment')}
              listenerFullComment={t('opinions.cards.three.full-comment')}
            />
          </div>
        </div>
        <div className="separator"></div>
        <div className="opinions-card">
          <div className="card">
            <CardOpinion
              listenerName={t('opinions.cards.four.name')}
              listenerComment={t('opinions.cards.four.comment')}
              listenerFullComment={t('opinions.cards.four.full-comment')}
            />
          </div>
          <div className="card">
            <CardOpinion
              listenerName={t('opinions.cards.five.name')}
              listenerComment={t('opinions.cards.five.comment')}
              listenerFullComment={t('opinions.cards.five.full-comment')}
            />
          </div>
          <div className="card">
            <CardOpinion
              listenerName={t('opinions.cards.six.name')}
              listenerComment={t('opinions.cards.six.comment')}
              listenerFullComment={t('opinions.cards.six.full-comment')}
            />
          </div>
        </div>
        {stepOpinions >= 1 && (
          <>
            <div className="separator"></div>
            <div className="opinions-card">
              <div className="card">
                <CardOpinion
                  listenerName={t('opinions.cards.seven.name')}
                  listenerComment={t('opinions.cards.seven.comment')}
                  listenerFullComment={t('opinions.cards.seven.full-comment')}
                />
              </div>
              <div className="card">
                <CardOpinion
                  listenerName={t('opinions.cards.eight.name')}
                  listenerComment={t('opinions.cards.eight.comment')}
                  listenerFullComment={t('opinions.cards.eight.full-comment')}
                />
              </div>
              <div className="card">
                <CardOpinion
                  listenerName={t('opinions.cards.nine.name')}
                  listenerComment={t('opinions.cards.nine.comment')}
                  listenerFullComment={t('opinions.cards.nine.full-comment')}
                />
              </div>
            </div>
          </>
        )}
        {stepOpinions >= 2 && (
          <>
            <div className="separator"></div>
            <div className="opinions-card">
              <div className="card">
                <CardOpinion
                  listenerName={t('opinions.cards.ten.name')}
                  listenerComment={t('opinions.cards.ten.comment')}
                  listenerFullComment={t('opinions.cards.ten.full-comment')}
                />
              </div>
              <div className="card">
                <CardOpinion
                  listenerName={t('opinions.cards.eleven.name')}
                  listenerComment={t('opinions.cards.eleven.comment')}
                  listenerFullComment={t('opinions.cards.eleven.full-comment')}
                />
              </div>
              <div className="card">
                <CardOpinion
                  listenerName={t('opinions.cards.twelve.name')}
                  listenerComment={t('opinions.cards.twelve.comment')}
                  listenerFullComment={t('opinions.cards.twelve.full-comment')}
                />
              </div>
            </div>
          </>
        )}
        {stepOpinions !== 2 && (
          <div className="more text-center">
            <button className="btn-blue" onClick={() => setStepOpinions(stepOpinions + 1)}>
              {t('opinions.more')}
            </button>
          </div>
        )}
        {stepOpinions === 2 && (
          <div className="more text-center">
            <span className="text-see-more-on-goole">
              Le reste des avis est disponible sur google en cliquant{' '}
              <a href={opinions_url} target="_blank" rel="noreferrer noopener">
                ici
              </a>
            </span>
          </div>
        )}
      </section>
    </>
  );
};

export default Coach;
