import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';
import { Helmet } from 'react-helmet';
import '../styles/pages/_surfCamp.scss';

const SurfCamp = () => {
  const { t } = useTranslation(['pages\\surfCampTr']);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title-html')}</title>
      </Helmet>
      <section className="img-background">
        <div className="main-title">
          <h1>{t('title')}</h1>
          <p className="description">{t('description')}</p>
        </div>
        <SocialNetworksMainBackground />
      </section>
      <section className="background-color">
        <div className="container">
          <div className="block">
            <div className="block-img">
              <img
                src="img/pages/surfCamp/surfeuse-face-coucher-soleil.webp"
                alt="Surfeuse face au coucher de soleil dans les Landes"
              />
            </div>
            <div className="block-description">
              <p className="title-global">{t('surf-camp.title')}</p>
              <p className="description-global justify">{t('surf-camp.description.part1')}</p>
              <p className="description-global justify">{t('surf-camp.description.part2')}</p>
            </div>
          </div>

          <div className="block reverse-columns">
            <div className="block-img">
              <img
                src="img/pages/surfCamp/session-surf-coucher-de-soleil-les-landes .webp"
                alt="Une session de surf au coucher de soleil dans les Landes"
              />
            </div>
            <div className="block-description">
              <p className="title-global">{t('about')}</p>
              <p className="description-global">
                <img src="img/pages/surfCamp/check.svg" alt="." />
                <span>{t('things.part1')}</span>
              </p>
              <p className="description-global">
                <img src="img/pages/surfCamp/check.svg" alt="." />
                <span>{t('things.part2')}</span>
              </p>
              <p className="description-global">
                <img src="img/pages/surfCamp/check.svg" alt="." />
                <span>{t('things.part3')}</span>
              </p>
              <p className="description-global">
                <img src="img/pages/surfCamp/check.svg" alt="." />
                <span>{t('things.part4')}</span>
              </p>
              <p className="description-global">
                <img src="img/pages/surfCamp/check.svg" alt="." />
                <span>{t('things.part5')}</span>
              </p>
            </div>
          </div>
          <div className="div-btn">
            <button onClick={() => navigate('/contacts?subject=[SURF CAMP]')} className="btn-transparent">
              {t('more')}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default SurfCamp;
