import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import { useSearchParams } from 'react-router-dom';
import '../../styles/components/forms/_form.scss';

interface INotif {
  listenerIsContact: boolean;
}

const Contact: React.FC<INotif> = ({ listenerIsContact }) => {
  const { t } = useTranslation(['components\\forms\\formTr']);

  const [isContact, setIsContact] = useState<boolean>(true);
  const [submitBtn, setSubmitBtn] = useState<string>('Envoyer');
  const [sent, setSent] = useState<boolean>(false);
  const [cssBtnDisabled, setCssBtnDisabled] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [begginer, setBegginer] = useState<string>('');
  const [intermediate, setIntermediate] = useState<string>('');
  const [confirmed, setConfirmed] = useState<string>('');
  const [searchParams] = useSearchParams();
  const [subject, setSubject] = useState<string>('');

  const [details, setDetails] = useState<string>('');

  useEffect(() => {
    if (searchParams.get('resa') !== null) {
      const values = searchParams.get('resa')?.split(';');
      if (values !== undefined) {
        setDetails(values[0] + ' - ' + values[1] + ' - ' + values[2]);
      }
    }
  }, []);

  useEffect(() => {
    const param: string | null = searchParams.get('subject');
    if (param !== null) {
      setSubject(param);
    }

    setIsContact(listenerIsContact);
    setSubmitBtn(t('contact.form.button'));
    if (t('contact.form.level.begginer') !== undefined) {
      setBegginer(t('contact.form.level.begginer'));
    }
    if (t('contact.form.level.intermediate') !== undefined) {
      setIntermediate(t('contact.form.level.begginer'));
    }
    if (t('contact.form.level.confirmed') !== undefined) {
      setConfirmed(t('contact.form.level.begginer'));
    }
  }, [listenerIsContact, t]);

  const sendEmail = (event: React.FormEvent<HTMLFormElement>) => {
    setSent(false);
    setError(false);
    event.preventDefault();
    setCssBtnDisabled('btn-blue-disabled');

    if (
      process.env.REACT_APP_EMAILJS_SERVICE_ID !== undefined &&
      process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT_ID !== undefined &&
      process.env.REACT_APP_EMAILJS_TEMPLATE_RESERVATION_ID !== undefined
    ) {
      const service_id = process.env.REACT_APP_EMAILJS_SERVICE_ID;
      let template_id = process.env.REACT_APP_EMAILJS_TEMPLATE_RESERVATION_ID;
      if (isContact) {
        template_id = process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT_ID;
      }

      emailjs.sendForm(service_id, template_id, event.currentTarget, process.env.REACT_APP_EMAILJS_PUBLIC_KEY).then(
        (result) => {
          // eslint-disable-next-line no-console
          console.info(result.text);
          setSent(true);
          setCssBtnDisabled('');
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error.text);
          setCssBtnDisabled('');
          setError(true);
        },
      );
    }
  };

  const [errorDetails, setErrorDetails] = useState<boolean>(false);

  const checkText = (e: string) => {
    // eslint-disable-next-line no-useless-escape
    const letters = /^[a-zA-Z0-9,;.?:/!"'àéèêâôöëä()\[\]\n_+ €$£-]{0,}$/;
    if (!e.match(letters)) {
      setErrorDetails(true);
      setCssBtnDisabled('btn-blue-disabled');
    } else {
      setErrorDetails(false);
      setCssBtnDisabled('');
    }
    setDetails(e);
  };

  const [cssTextInfos, setCssTextInfos] = useState<string>('none-text-infos');
  const changeTextInfos = () => {
    if (cssTextInfos === '') {
      setCssTextInfos('none-text-infos');
    } else {
      setCssTextInfos('');
    }
  };

  const setSubjectValue = (value: string) => {
    // eslint-disable-next-line no-useless-escape
    const letters = /^[a-zA-Z0-9,;.?:/!"'àéèêâôöëä()\[\]_+ -]{0,}$/;

    if (value.match(letters)) {
      setSubject(value);
    }
  };

  return (
    <div className="form" id="bookingStart">
      <div className="container">
        <div className="block">
          <div className="title-global">
            <p>{t('contact.title')}</p>
          </div>

          <div className="description-global">
            <p>{t('contact.description')}</p>
          </div>
          <a href={'tel:' + t('contact.phone')}>
            <div className="coords">
              <img src="img/components/tools/phone.svg" alt="Phone" />
              <p>{t('contact.phone')}</p>
            </div>
          </a>
          <a
            href={
              'https://www.google.com/maps/place/SURF+PERSPECTIVE/@43.545614,-1.4937665,17z/data=!3m1!4b1!4m5!3m4!1s0xd51419c7800983f:0x5b61b83d360d73ba!8m2!3d43.5456101!4d-1.4915778?hl=fr'
            }
          >
            <div className="coords">
              <img src="img/components/tools/map-blue.svg" alt="Maps" />
              <p>{t('contact.address')}</p>
            </div>
          </a>
          <div className="social-networks">
            <a href={'https://instagram.com/surf_perspective_tarnos?igshid=YmMyMTA2M2Y='}>
              <img src="img/components/tools/instagram.svg" alt="Instagram" />
            </a>
            <a href={'https://www.facebook.com/surfperspectivetarnos/?ref=page_internal'}>
              <img className="notMr30" src="img/components/tools/facebook.svg" alt="Facebook" />
            </a>
          </div>
        </div>
        <div className="block">
          <form onSubmit={sendEmail} className="form">
            <div className="block-two">
              <div className="input">
                <label>
                  {t('contact.form.name')}
                  <input
                    type="text"
                    required
                    pattern="[a-zA-Zàéèêâôöëä]{2,}"
                    name="lastname"
                    title={t('errorMsg.alpha')}
                  />
                </label>
              </div>
              <div className="input">
                <label>
                  {t('contact.form.firstname')}
                  <input
                    type="text"
                    required
                    pattern="[a-zA-Zàéèêâôöëä]{2,}"
                    name="firstname"
                    title={t('errorMsg.alpha')}
                  />
                </label>
              </div>
            </div>
            <div className="block-one">
              <div className="input">
                <label>
                  {t('contact.form.mail')}
                  <input
                    type="text"
                    required
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                    name="mail"
                    title={t('errorMsg.mail')}
                  />
                </label>
              </div>
            </div>
            <div className="block-two">
              <div className="input">
                <label>
                  {t('contact.form.phone')}
                  <input type="text" name="phone" pattern="[0-9]{10,15}" title={t('errorMsg.phone')} />
                </label>
              </div>

              {isContact && (
                <div className="input">
                  <label>
                    {t('contact.form.subject')}
                    <input
                      type="text"
                      name="subject"
                      title={t('errorMsg.alphanumeric-spec')}
                      value={subject}
                      onChange={(e) => setSubjectValue(e.target.value)}
                    />
                  </label>
                </div>
              )}

              {!isContact && (
                <div className="input">
                  <label>
                    {t('contact.form.date')}
                    <input required type="date" name="date" />
                  </label>
                </div>
              )}
            </div>

            {!isContact && (
              <div className="block-two">
                <div className="input">
                  <label>
                    {t('contact.form.level.title')}
                    <select name="level">
                      <option value={begginer}> {t('contact.form.level.begginer')} </option>
                      <option value={intermediate}>{t('contact.form.level.intermediate')}</option>
                      <option value={confirmed}>{t('contact.form.level.confirmed')}</option>
                    </select>
                  </label>
                </div>
                <div className="input">
                  <label>
                    <div className="label-infos">
                      {t('contact.form.number')}
                      <div className="btn-infos" onClick={changeTextInfos}>
                        i
                      </div>
                      <div className={'infos-text ' + cssTextInfos}>{t('errorMsg.nb-persons')}</div>
                    </div>

                    <input type="text" pattern="[0-9]{1,}" name="number" title={t('errorMsg.numeric')} />
                  </label>
                </div>
              </div>
            )}
            <div className="block-one">
              <div className="input">
                <label>
                  {t('contact.form.details')}
                  <textarea
                    maxLength={40000}
                    onChange={(e) => checkText(e?.target.value)}
                    name="details"
                    value={details}
                  />
                </label>

                {errorDetails && <p className="error-msg">{t('errorMsg.alphanumeric-spec')}</p>}
              </div>
            </div>
            <div className="btn">
              {cssBtnDisabled === '' && !errorDetails && (
                <input className={'btn-blue'} type="submit" value={submitBtn} />
              )}
              {(cssBtnDisabled !== '' || errorDetails) && (
                <input className={'btn-blue ' + cssBtnDisabled} disabled type="submit" value={submitBtn} />
              )}
            </div>
            {error && (
              <p className="mail-not-sent">
                <span className="span-red"></span>
                {t('errorMsg.mail-not-sent')}
              </p>
            )}
            {sent && (
              <p className="mail-sent">
                <span className="span-green"></span>
                {t('errorMsg.mail-sent')}
              </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
