import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';
import { Helmet } from 'react-helmet';
import '../styles/pages/_gallery.scss';

const Gallery = () => {
  const { t } = useTranslation(['pages\\galleryTr']);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [cptViewMore, setCptViewMore] = useState<number>(0);
  const [block2, setBlock2] = useState('block-none');
  const [block3, setBlock3] = useState('block-none');
  const [block4, setBlock4] = useState('block-none');
  const [btnViewMore, setBtnViewMore] = useState('');

  const displayImages = () => {
    switch (cptViewMore) {
      case 0:
        setCptViewMore(cptViewMore + 1);
        setBlock2('');
        break;
      case 1:
        setCptViewMore(cptViewMore + 1);
        setBlock3('');
        break;
      case 2:
        setCptViewMore(cptViewMore + 1);
        setBlock4('');
        setBtnViewMore('block-none');
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('title-html')}</title>
      </Helmet>
      <section className="img-background">
        <div className="main-title">
          <h1>{t('title')}</h1>
        </div>

        <SocialNetworksMainBackground />
      </section>
      <section className="color-back">
        <div className="container">
          <div className="">
            <img
              className="img-square"
              src="img/pages/gallery/surfeur-en-route-vers-ocean.webp"
              alt="Surfeur en route vers l'océan pour une session de Surf à Tarnos"
            />
            <img
              className="img-square"
              src="img/pages/gallery/echauffement-surf.webp"
              alt="Echauffement Surf sur l'un des plus beaux spots de surf du Maroc"
            />
            <img
              className="img-square"
              src="img/pages/gallery/souvenirs-cours-surf-maroc.webp"
              alt="Souvenirs d'un cours de surf au Maroc, élève et moniteur de surf prennent la pose"
            />
            <img
              className="img-square"
              src="img/pages/gallery/explication-technique-redressement-surf.webp"
              alt="Explication de la technique du redressement en surf sur la plage de Zumaia en Espagne"
            />
            <img
              className="img-rect"
              src="img/pages/gallery/planche-surf-face-ocean.webp"
              alt="Planche de surf face à l'océan"
            />
          </div>
          <div className={block2}>
            <img
              className="img-rect"
              src="img/pages/gallery/surfeur-realisant-manoeuvre-replacement.webp"
              alt="Surfeur réalisant une manoeuvre de replacement sur les vagues de La barre à Anglet"
            />
            <img
              className="img-square"
              src="img/pages/gallery/moniteur-surf-avec-planche-fetishe.webp"
              alt="Moniteur de surf avec sa planche fétishe"
            />
            <img
              className="img-square"
              src="img/pages/gallery/groupe-surfeurs-imsouane-maroc.webp"
              alt="Groupe de surfeurs à Imsouane au Maroc unis à l'occasion de l'évènement Rame pour ta planète"
            />
            <img
              className="img-square"
              src="img/pages/gallery/sejour-adolescent-surf-coucher-soleil-tarnos.webp"
              alt="Séjour adolescent surf au coucher de soleil à Tarnos"
            />
            <img
              className="img-square"
              src="img/pages/gallery/cours-surf-particulier-enfant-tarnos.webp"
              alt="Cours de surf particulier enfant à Tarnos"
            />
          </div>
          <div className={block3}>
            <img
              className="img-rect"
              src="img/pages/gallery/passion-surf-transpire-a-imsouane.webp"
              alt="La passion du surf transpire à Imsouane"
            />
            <img
              className="img-square"
              src="img/pages/gallery/cours-surf-collectif-enfants-tarnos-les-landes.webp"
              alt="Cours de Surf collectif enfants à Tarnos dans les Landes"
            />
            <img
              className="img-square"
              src="img/pages/gallery/cours-surf-famille-pere-fils-capbreton-les-landes.webp"
              alt="Cours de surf en famille père et fils à Capbreton dans les Landes"
            />
            <img
              className="img-rect"
              src="img/pages/gallery/silhouettes-surfeurs-promeneurs-coucher-soleil-plage-du-metro-tarnos.webp"
              alt="Silhouettes de surfeurs et promeneurs au coucher de soleil de la plage du métro à Tarnos"
            />
          </div>
          <div className={block4}>
            <img
              className="img-square"
              src="img/pages/gallery/cours-surf-collectif-decouverte-tarnos-plage-du-metro.webp"
              alt="Cours de Surf collectif découverte à Tarnos plage du Métro"
            />
            <img
              className="img-square"
              src="img/pages/gallery/correction-posture-surf-capbreton.webp"
              alt="Correction de posture surf sur le sable en petit groupe à Capbreton"
            />
            <img
              className="img-square"
              src="img/pages/gallery/sejour-surf-enfant-itinerance-pays-basque-les-landes.webp"
              alt="Séjour surf enfant en itinérance dans le Pays-Basque et les Landes"
            />
            <img
              className="img-square"
              src="img/pages/gallery/coaching-video-surf-les-landes.webp"
              alt="Coaching vidéo surf dans les Landes, session d'analyse sur le sable"
            />
            <img
              className="img-rect"
              src="img/pages/gallery/surfeurs-participants-evenement-rame-pour-ta-planete-maroc.webp"
              alt="Surfeurs participants à l'évènement Rame Pour Ta Planète au Maroc"
            />
          </div>
          <div className={'div-btn-more ' + btnViewMore}>
            <button className="btn-blue" onClick={displayImages}>
              <span>{t('more')}</span>
              <img src="img/pages/gallery/arrow-down.svg" alt="+" />
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
