import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Contact from '../components/forms/Contact';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';
import { Helmet } from 'react-helmet';

const Booking = () => {
  const { t } = useTranslation(['components\\forms\\formTr']);

  const [showUl, setShowUl] = useState('');
  const [displayPopUp, setDisplayPopUp] = useState<boolean>(false);
  const [displayPopUpUnavailableSite, setDisplayPopUpUnavailableSite] = useState<boolean>(false);
  const [isUnavailableSite, setIsUnavailableSite] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsUnavailableSite(process.env.REACT_APP_IS_SEIGNANX_SITE_UNVAILABLE === '1');
  }, []);

  const setSelect = () => {
    if (showUl == '') {
      setShowUl('show-ul');
    } else {
      setShowUl('');
    }
  };

  const linkToSeignanxSite = (urlSite: string) => {
    if (isUnavailableSite) {
      setDisplayPopUpUnavailableSite(true);
    } else {
      window.open(urlSite);
      setShowUl('');
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('title-html-booking')}</title>
      </Helmet>
      <section className="img-background">
        <div className="main-title">
          <h1>{t('booking-title')}</h1>
        </div>
        <SocialNetworksMainBackground />
      </section>
      <section className="title-options">
        <span>{t('options.title')}</span>
      </section>
      <section className="choice">
        <div className="choices">
          <div className="block-hand">
            <img src="./img/components/form/left-hand-gradiant.png" alt="" />
            <div className="block red-one">
              <div className="texts">
                <p className="main">{t('options.blocks.blue.content.one')}</p>
                <p className="italic">{t('options.blocks.blue.content.two')}</p>
                <p className="main">{t('options.blocks.blue.content.three')}</p>
                <div className="book">
                  <span className="select-custom blue-btn" onClick={setSelect}>
                    {t('options.blocks.blue.select.title')}
                    <img src="./img/components/form/arrow-down-white.svg" alt="arrow" />
                  </span>
                </div>
                <div className="center-ul">
                  <ul className={showUl}>
                    <li
                      onClick={() =>
                        linkToSeignanxSite(
                          'https://reservation.seignanx.com/cours-collectif-1-seance-2-heures.html#media',
                        )
                      }
                    >
                      {t('options.blocks.blue.select.options.classics.one-day')}
                    </li>
                    <li
                      onClick={() =>
                        linkToSeignanxSite(
                          'https://reservation.seignanx.com/cours-collectif-3-seances-2-heures.html#media',
                        )
                      }
                    >
                      {t('options.blocks.blue.select.options.classics.three-days')}
                    </li>
                    <li
                      onClick={() =>
                        linkToSeignanxSite(
                          'https://reservation.seignanx.com/cours-collectif-4-seances-2-heures.html#media',
                        )
                      }
                    >
                      {t('options.blocks.blue.select.options.classics.four-days')}
                    </li>
                    <li
                      onClick={() =>
                        linkToSeignanxSite(
                          'https://reservation.seignanx.com/cours-collectif-5-seances-2-heures.html#media',
                        )
                      }
                    >
                      {t('options.blocks.blue.select.options.classics.five-days')}
                    </li>
                  </ul>
                </div>
                <p className="italic">{t('options.blocks.blue.ps')}</p>
              </div>
            </div>
          </div>
          <div className="block-hand">
            <img src="./img/components/form/right-hand-gradiant.png" alt="" />
            <div className="block blue-one">
              <div className="texts">
                <p className="main">
                  <span>{t('options.blocks.red.content.one')}</span>
                  <span>{t('options.blocks.red.content.two')}</span>
                </p>
                <p className="main">{t('options.blocks.red.content.three')}</p>
                <div className="book">
                  <a href="#bookingStart">
                    <button className="red-btn">{t('options.blocks.red.button')}</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="separator">
        <span />
      </div>
      <Contact listenerIsContact={false} />

      {displayPopUp && (
        <div className="pop-up">
          <div className="content-res">
            <h2>{t('pop-up.title')}</h2>
            <div className="choices">
              <button onClick={() => setDisplayPopUp(false)}>{t('pop-up.ok')}</button>
            </div>
          </div>
        </div>
      )}

      {displayPopUpUnavailableSite && (
        <div className="pop-up" style={{ zIndex: '4' }}>
          <div className="content-res">
            <h2>{t('pop-up-unvailable-site')}</h2>
            <div className="choices">
              <button onClick={() => setDisplayPopUpUnavailableSite(false)}>{t('pop-up.ok')}</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Booking;
