import React from 'react';
import Navbar from '../tools/Navbar';
import Footer from '../tools/Footer';

interface LayoutProps {
  children: React.ReactNode; // Typage explicite
  classname: string;
}

const Layout: React.FC<LayoutProps> = ({ children, classname }) => {
  return (
    <>
      <Navbar />
      <main className={classname}>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
